import axios from "axios";
import { BRAND_BASE_URL } from "./BaseURL";
import getHeaders from "../constants/headers";

export const getViewNotificationTemplate = async () => {
  return axios({
    method: "get",
    url: BRAND_BASE_URL + "/store/api/secure/v1/template-config", // for android use

    headers: getHeaders(),
  });
};

export const postNotificationTemplate = async (data) => {
  return axios({
    method: "post",
    url: BRAND_BASE_URL + "/store/api/secure/v1/template-config", // for android use
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const putNotificationTemplate = async (data) => {
  return axios({
    method: "put",
    url: BRAND_BASE_URL + "/store/api/secure/v1/template-config", // for android use
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

