import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import {
  Card,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
  Button,
} from "react-bootstrap";
import "../index.css";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { getBrandStore, getDataByInvoice } from "../../services/regenerate";
import { getAllBrands } from "../../services/brandApi";
import { Dropdown } from "primereact/dropdown";
import { closeOrder, closeOrderInfo } from "../../services/updateOrderStatus";
import { successMessage } from "../../utils/alertMessage";

const UpdateOrderStatus = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [brandStoreList, setBrandStoreList] = useState([]);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const [customerData, setCustomerData] = useState(null);
 

  const [input, setInput] = useState({
    brand: "",
    store: "",
    invoiceNo: "",
  });
  const [invoiceNoList, setInvoiceNoList] = useState([]);
  const [orderStatus, setOrderStatus] = useState({
    name: "",
    value: "",
  });
  // ...
  const handleInvoiceNoAdd = () => {
    setInvoiceNoList((prev) => {
      return [...prev, input.invoiceNo];
    });
  };

  const handleDeleteInvoiceNo = (index) => {
    setInvoiceNoList((prev) => prev.filter((_, i) => i !== index));
  };

  const orderStatusList = [
    {
      name: "PROCESSED",
      value: "PROCESSED",
    },
    {
      name: "DELIVERED",
      value: "DELIVERED",
    },
  ];
  

  const handleStatusChange = (e) => {
    setOrderStatus(e.value);
  };

  const fetchAllBrands = () => {
    setLoading(true);
    getAllBrands()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const getAllBrands = Res.map((item, index) => ({
            name: item.name,
            value: item.id,
          }));
          setBrandList(getAllBrands);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchAllBrands();
  }, []);

  const handleBrandIdData = (e) => {
    setInput({
      ...input,
      brand: e.target.value,
    });
    getBrandStore(e.target.value.value)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const getBrandStore = Res.map((item, index) => ({
            name: item.storeName,
            value: item.id,
          }));
          setBrandStoreList(getBrandStore);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleSubmit = async () => {
    setSubmitButtonPressed(true);
    const data = {
      // status: "PROCESSED",
      invoiceList: invoiceNoList,
    };
    closeOrderInfo(input.brand.value, input.store.value, data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setCustomerData(Res);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleCloseOrder = () => {
    if (!customerData) return;
    setLoading(true);
    const data = {
      status: orderStatus.value,
      invoiceList: invoiceNoList,
    };
    closeOrder(input.brand.value, input.store.value, data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
           if(Res.status === true) {
                      successMessage("Update Successfully");
                    }
                     
                    setInput({
                      brand: "",
                      store: "",
                      invoiceNo: "",
                    });
                  setSubmitButtonPressed(false);
          setCustomerData(null);
          setOrderStatus({});
          setInvoiceNoList([])
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h3>Update Order Status</h3>
            </div>
            <main id="main" className="main">
              <section className="section">
                <div className="row">
                  <div className="col-lg-12">
                    <Card
                      style={{
                        backgroundColor: "#1752AA",
                        color: "white",
                        marginTop: 20,
                      }}
                    >
                      <Card.Body>
                        <div>
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label htmlFor="category" className="form-label">
                                Brand Selection
                              </label>
                            </div>
                            <Dropdown
                              value={input.brand}
                              options={brandList}
                              optionLabel="name"
                              onChange={handleBrandIdData}
                              placeholder="Select a Brand"
                            />
                          </div>

                          <div>
                            <div className="row mb-3">
                              <div className="col-md-12">
                                <label
                                  htmlFor="qrCodeCount"
                                  className="form-label"
                                >
                                  Store Selection
                                </label>
                              </div>
                              <Dropdown
                                value={input.store}
                                options={brandStoreList}
                                optionLabel="name"
                                onChange={(e) => {
                                  setInput({
                                    ...input,
                                    store: e.target.value,
                                  });
                                }}
                                placeholder="Select a Store"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="row mb-3">
                              <div className="col-md-12">
                                <label htmlFor="status" className="form-label">
                                  Order Status
                                </label>
                              </div>
                              <Dropdown
                                value={orderStatus}
                                options={orderStatusList}
                                optionLabel="name"
                                onChange={handleStatusChange}
                                placeholder="Select a Order Status"
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label
                                htmlFor="garmentName"
                                className="form-label"
                              >
                                Invoice No.
                              </label>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                textAlign: "center",
                              }}
                            >
                              <InputText
                                type="text"
                                style={{ width: "100%", marginRight: "10px" }}
                                id="invoiceNo"
                                name="invoiceNo"
                                value={input.invoiceNo}
                                onChange={(e) => {
                                  setInput({
                                    ...input,
                                    invoiceNo: e.target.value,
                                  });
                                }}
                                readonly
                              />
                              <button
                                variant="contained"
                                onClick={handleInvoiceNoAdd}
                                disabled={submitButtonPressed}
                                className="move-btn-a move-btn-mu"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "5px",
                              marginRight: "5px",
                              width: "500px",
                              overflow: "auto",
                            }}
                          >
                            {invoiceNoList.map((item, index) => (
                              <div
                                key={index}
                                style={{
                                  marginLeft: "5px",
                                }}
                              >
                                <span>{item}</span>
                                <button
                                  className="btn btn-danger"
                                  onClick={() => handleDeleteInvoiceNo(index)}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <i className="bi bi-trash3-fill"></i>
                                </button>
                              </div>
                            ))}
                          </div>

                          <div className="col-md-12 text-center">
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="move-btn-a move-btn-mu"
                              disabled={submitButtonPressed}
                              style={{ cursor: "pointer", }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </section>
            </main>
          </Col>
          <Col style={{ marginTop: 100 }}>
            {customerData !== null && (
              <React.Fragment>
                <Row>
                  <Col>
                    <div className="col-lg-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Customer Name</th>
                            <th>Mobile No.</th>
                            <th>Invoice No.</th>
                            <th>Order No.</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.customerName}</td>
                              <td>{item.customerMobile}</td>
                              <td>{item.invoiceNo}</td>
                              <td>{item.orderNo}</td>
                              <td>{item.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="col-md-12  ">
                      <button
                        className="move-btn move-btn-su"
                        style={{ cursor: "pointer" ,width: "100%"}}
                        onClick={handleCloseOrder}
                      >
                        Confirm and Continue
                      </button>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UpdateOrderStatus;
