import axios from "axios";
import { BRAND_BASE_URL, CONSUMER_BASE_URL } from "./BaseURL";
import getHeaders from "../constants/headers";

export const brandLocalityUpload = (brandId, uploadFile) => {
  const formData = new FormData();
  formData.append("uploadFile", uploadFile); // Assuming 'file' is the file object you want to upload

  return axios({
    method: "post",
    url:
      CONSUMER_BASE_URL + "/api/secure/v1/brand-locality/" + brandId + "/upload-list",
    data: formData,
    headers: getHeaders("multipart/form-data"),
  });
};

export const brandOldOrderUpload = (brandId, uploadFile) => {
  const formData = new FormData();
  formData.append("uploadFile", uploadFile); // Assuming 'file' is the file object you want to upload

  return axios({
    method: "post",
    url:
      BRAND_BASE_URL + "/brand/api/secure/v1/old-order/" + brandId + "/upload-order",
    data: formData,
    headers: getHeaders("multipart/form-data"),
  });
};

