import React, { useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "../index.css";
import { useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { postNotificationTemplate } from "../../services/NotificationTemplate";
import { Dropdown } from "primereact/dropdown";

const AddNotificationTemplate = () => {
  const navigate = useNavigate();

  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);
  const [input, setInput] = useState({
    templateType: "",
    immediateNotify: "",
    template: "",
    mode: "default",
    whatsappTemplateApprovedName: "",
    autoNotifyEnabled: "",
  });
  const [errors, setErrors] = useState({
    templateType: "",
    immediateNotify: "",
    template: "",
    autoNotifyEnabled: "",
  });

  const statusList = [
    {
      label: "ACTIVE",
      value: true,
    },
    {
      label: "INACTIVE",
      value: false,
    },
  ];

  const handleSubmit = () => {
    if (input.templateType === "") {
      setErrors({
        ...errors,
        templateType: "Please Enter Template Type",
      });
    } else if (input.template === "") {
      setErrors({
        ...errors,
        template: "Please Enter Template",
      });
    } else if (input.immediateNotify === "") {
      setErrors({
        ...errors,
        immediateNotify: "Please Select Immediate Notify",
      });
    } else if (input.autoNotifyEnabled === "") {
      setErrors({
        ...errors,
        autoNotifyEnabled: "Please Select Auto Notify Enabled",
      });
    } else {
      setSubmitButtonPressed(true);
      const data = {
        ...input,
        immediateNotify: input.immediateNotify.value,
        autoNotifyEnabled: input.autoNotifyEnabled.value,
      };

      if (!data) {
        console.error("No file selected");
        return;
      }

      postNotificationTemplate(data)
        .then((response) => {
          // handle the response
          if (response.data.status === true) {
            successMessage(response.data.message);
            setSubmitButtonPressed(false);
            navigate("/notification-template");
          } else {
            errorMessage(response.data.message);
            setSubmitButtonPressed(false);
          }
        })
        .catch((error) => {
          // handle errors
          errorMessage("Failed");
        });
    }
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate("/notification-template")}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div>
              <div style={{ textAlign: "center" }}>
                <h3>Add Notification Template </h3>
              </div>
              <main id="main" className="main mb-3">
                <section className="section">
                  <div className="col-lg-12">
                    <div>
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <label htmlFor="templateType" className="form-label">
                            Template Name
                          </label>

                          <InputText
                            type="text"
                            style={{ width: "100%" }}
                            id="templateType"
                            name="templateType"
                            value={input.templateType}
                            onChange={(e) => {
                              setInput({
                                ...input,
                                templateType: e.target.value,
                              });
                              setErrors({
                                ...errors,
                                templateType: "",
                              });
                            }}
                          />
                          {errors.templateType && (
                            <p style={{ color: "red", margin: 0 }}>
                              {errors.templateType}
                            </p>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="whatsappTemplateApprovedName"
                            className="form-label"
                          >
                            WhatsApp Template Approved Name
                          </label>

                          <InputText
                            type="text"
                            style={{ width: "100%" }}
                            id="whatsappTemplateApprovedName"
                            name="whatsappTemplateApprovedName"
                            value={input.whatsappTemplateApprovedName}
                            onChange={(e) => {
                              setInput({
                                ...input,
                                whatsappTemplateApprovedName: e.target.value,
                              });
                              setErrors({
                                ...errors,
                                whatsappTemplateApprovedName: "",
                              });
                            }}
                          />
                          {errors.whatsappTemplateApprovedName && (
                            <p style={{ color: "red", margin: 0 }}>
                              {errors.whatsappTemplateApprovedName}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label htmlFor="template" className="form-label">
                            Template
                          </label>

                          <InputTextarea
                            style={{ width: "100%" }}
                            id="template"
                            name="template"
                            value={input.template}
                            onChange={(e) => {
                              setInput({
                                ...input,
                                template: e.target.value,
                              });
                              setErrors({
                                ...errors,
                                template: "",
                              });
                            }}
                            rows={5}
                            cols={30}
                          />
                          {errors.template && (
                            <p style={{ color: "red", margin: 0 }}>
                              {errors.template}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="mt-3">
                        <label htmlFor="brandId" className="form-label">
                          Immediate Notify
                        </label>
                        <Dropdown
                          value={input.immediateNotify}
                          options={statusList}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              immediateNotify: e.target.value,
                            });
                          }}
                          optionLabel="label"
                          placeholder="Select a Immediate Notify"
                        />
                        {errors.immediateNotify && (
                          <p style={{ color: "red", margin: 0 }}>
                            {errors.immediateNotify}
                          </p>
                        )}
                      </div>
                      <div className="mt-3">
                        <label htmlFor="brandId" className="form-label">
                          Auto Notify Enabled
                        </label>
                        <Dropdown
                          value={input.autoNotifyEnabled}
                          options={statusList}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              autoNotifyEnabled: e.target.value,
                            });
                          }}
                          optionLabel="label"
                          placeholder="Select a Immediate Notify"
                        />
                        {errors.autoNotifyEnabled && (
                          <p style={{ color: "red", margin: 0 }}>
                            {errors.autoNotifyEnabled}
                          </p>
                        )}
                      </div>

                      <div>
                        <div className="col-md-12 mt-4 text-center">
                          <button
                            onClick={() => handleSubmit()}
                            className="move-btn move-btn-su"
                            disabled={submitButtonPressed}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddNotificationTemplate;
