import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Root from "../authLayout/Root";
import AuthLayout from "../authLayout/AuthLayout";
import Authentication from "../authLayout/Authentication";
import Dashboard from "../pages/Dashboard";
import AddGarment from "../pages/AddGarment";
import GarmentView from "../pages/GarmentView";
import AddAppService from "../pages/AddAppService";
import AppServicesView from "../pages/AppServicesView";
import AllBrandList from "../pages/BrandComponent/AllBrandList";
import CreateBrand from "../pages/BrandComponent/CreateBrand";
import ViewBrandDetails from "../pages/BrandComponent/ViewBrandDetails";
import ConfigUser from "../pages/BrandComponent/ConfigUser";
import AddAdminUser from "../pages/BrandComponent/AddAdminUser";
import Regenerate from "../pages/BrandComponent/Regenerate/Regenerate";
import UpdateOrderStatus from "../pages/BrandComponent/UpdateOrderStatus";
import BrandCustomerUpload from "../pages/BrandComponent/BrandCustomerUpload";
import ViewRemarksType from "../pages/RemarksType/ViewRemarksType";
import AddRemarksType from "../pages/RemarksType/AddRemarksType";
import OrderCancel from "../pages/BrandComponent/OrderCancel";
import ViewNotificationTemplate from "../pages/Template/ViewNotificationTemplate";
import AddGarmentCategory from "../pages/UploadFileComponent/AddGarmentCategory";
import AddPriceGarmentService from "../pages/UploadFileComponent/AddPriceGarmentService";
import AddPackageGarmentService from "../pages/UploadFileComponent/AddPackageGarmentService";
import BrandWhatsAppConfig from "../pages/WhatsAppConfig/BrandWhatsAppConfig";
import AddBrandWhatsAppConfig from "../pages/WhatsAppConfig/AddBrandWhatsAppConfig";
import EditBrandWhatsAppConfig from "../pages/WhatsAppConfig/EditBrandWhatsAppConfig";
import AddNotificationTemplate from "../pages/Template/AddNotificationTemplate";
import EditNotificationTemplate from "../pages/Template/EditNotitficationTemplate";
import BrandNotification from "../pages/BrandComponent/BrandNotification/BrandNotification";
import AddMapBrandNotificationTemplate from "../pages/BrandComponent/BrandNotification/AddMapBrandNotificationTemplate";
import EditMapBrandNotificationTemplate from "../pages/BrandComponent/BrandNotification/EditMapBrandNotificationTemplate";
import SwapOrder from "../pages/SwapOrder";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
      </Route>
      <Route path="/" element={<Authentication />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/all-brands" element={<AllBrandList />} />
        <Route path="/config-user" element={<ConfigUser />} />
        <Route path="/add-admin-user" element={<AddAdminUser />} />
        <Route path="/add-brand" element={<CreateBrand />} />
        <Route path="/view-brand-details" element={<ViewBrandDetails />} />
        <Route path="/add-garment" element={<AddGarment />} />
        <Route path="/view-garment" element={<GarmentView />} />
        <Route path="/app-service" element={<AddAppService />} />
        <Route path="/view-app-services" element={<AppServicesView />} />
        <Route path="/regenerate" element={<Regenerate />} />
        <Route path="/update-order-status" element={<UpdateOrderStatus />} />
        <Route
          path="/brand-customer-upload"
          element={<BrandCustomerUpload />}
        />
        <Route path="/add-remarks-type" element={<AddRemarksType />} />
        <Route path="/view-remarks-type" element={<ViewRemarksType />} />
        <Route path="/order-cancel" element={<OrderCancel />} />
        <Route
          path="/notification-template"
          element={<ViewNotificationTemplate />}
        />
        <Route
          path="/add-notification-template"
          element={<AddNotificationTemplate />}
        />
        <Route
          path="/edit-notification-template"
          element={<EditNotificationTemplate />}
        />
        <Route path="/add-garment-category" element={<AddGarmentCategory />} />
        <Route
          path="/add-price-garment-service"
          element={<AddPriceGarmentService />}
        />
        <Route
          path="/add-package-garment-service"
          element={<AddPackageGarmentService />}
        />
        <Route
          path="/brand-whats-app-config"
          element={<BrandWhatsAppConfig />}
        />
        <Route
          path="/add-brand-whats-app-config"
          element={<AddBrandWhatsAppConfig />}
        />
        <Route
          path="/edit-brand-whats-app-config"
          element={<EditBrandWhatsAppConfig />}
        />
        <Route path="/brand-notification" element={<BrandNotification />} />
        <Route
          path="/add-map-brand-notification-template"
          element={<AddMapBrandNotificationTemplate />}
        />
        <Route
          path="/edit-map-brand-notification-template"
          element={<EditMapBrandNotificationTemplate />}
        />
        <Route path="/swap-order" element={<SwapOrder />} />
      </Route>
      <Route path="logout" element={<Logout />} />
    </Route>
  )
);

export default router;
