import React, { useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "../index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { Dropdown } from "primereact/dropdown";
import { useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { putNotificationTemplate } from "../../services/NotificationTemplate";
import { InputTextarea } from "primereact/inputtextarea";

const EditNotificationTemplate = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);
  const [input, setInput] = useState({
    templateType: state?.templateType || "",
    immediateNotify: state?.immediateNotify || "",
    autoNotifyEnabled: state?.autoNotifyEnabled || "",
    template: state?.template || "",
    mode: "default",
    whatsappTemplateApprovedName: state?.whatsappTemplateApprovedName || "",
    status: state?.status || "",
  });
  const [errors, setErrors] = useState({
    // brandId: "",
    templateType: "",
    template: "",
    autoNotifyEnabled: "",
  });

  useEffect(() => {
    if (state !== null) {
      setInput({
        ...input,
        immediateNotify: {
          label: state.immediateNotify === true ? "ACTIVE" : "INACTIVE",
          value: state.immediateNotify,
        },
        autoNotifyEnabled: {
          label: state.autoNotifyEnabled === true ? "ACTIVE" : "INACTIVE",
          value: state.autoNotifyEnabled,
        },
        status: {
          label: state.status === true ? "ACTIVE" : "INACTIVE",
          value: state.status,
        },
      });
    }
  }, [state]);

  const StatusList = [
    {
      label: "ACTIVE",
      value: true,
    },
    {
      label: "INACTIVE",
      value: false,
    },
  ];

  const immediateNotifyList = [
    {
      label: "ACTIVE",
      value: true,
    },
    {
      label: "INACTIVE",
      value: false,
    },
  ];

  const handleSubmit = () => {
    if (input.templateType === "") {
      setErrors({
        ...errors,
        templateType: "Please Enter Template Type",
      });
    } else if (input.template === "") {
      setErrors({
        ...errors,
        template: "Please Enter Template",
      });
    } else if (input.immediateNotify === "") {
      setErrors({
        ...errors,
        immediateNotify: "Please Select Immediate Notify",
      });
    } else if (input.autoNotifyEnabled === "") {
      setErrors({
        ...errors,
        autoNotifyEnabled: "Please Select Auto Notify Enabled",
      });
    } else {
      setSubmitButtonPressed(true);

      const data = {
        ...state,
        mode: input.mode,
        template: input.template,
        templateType: input.templateType,
        whatsappTemplateApprovedName: input.whatsappTemplateApprovedName,
        immediateNotify: input.immediateNotify.value,
        autoNotifyEnabled: input.autoNotifyEnabled.value,
        status: input.status.value,
      };

      if (!data) {
        console.error("No file selected");
        return;
      }
      putNotificationTemplate(data)
        .then((response) => {
          // handle the response
          if (response.data.status === true) {
            successMessage(response.data.message);
            setSubmitButtonPressed(false);
            navigate("/notification-template");
          } else {
            errorMessage(response.data.message);
            setSubmitButtonPressed(false);
          }
        })
        .catch((error) => {
          // handle errors
          errorMessage("Failed");
          setSubmitButtonPressed(false);
        });
    }
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate("/notification-template")}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div>
              <div style={{ textAlign: "center" }}>
                <h3>Edit - {state.templateType} Template </h3>
              </div>
              <main id="main" className="main mb-3">
                <section className="section">
                  <div className="col-lg-12">
                    <div>
                      <div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <label
                              htmlFor="templateType"
                              className="form-label"
                            >
                              Template Name
                            </label>

                            <InputText
                              type="text"
                              style={{ width: "100%" }}
                              id="templateType"
                              name="templateType"
                              value={input.templateType}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  templateType: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  templateType: "",
                                });
                              }}
                            />
                            {errors.templateType && (
                              <p style={{ color: "red", margin: 0 }}>
                                {errors.templateType}
                              </p>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="whatsappTemplateApprovedName"
                              className="form-label"
                            >
                              WhatsApp Template Approved Name
                            </label>

                            <InputText
                              type="text"
                              style={{ width: "100%" }}
                              id="whatsappTemplateApprovedName"
                              name="whatsappTemplateApprovedName"
                              value={input.whatsappTemplateApprovedName}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  whatsappTemplateApprovedName: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  whatsappTemplateApprovedName: "",
                                });
                              }}
                            />
                            {errors.whatsappTemplateApprovedName && (
                              <p style={{ color: "red", margin: 0 }}>
                                {errors.whatsappTemplateApprovedName}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <label htmlFor="template" className="form-label">
                              Template
                            </label>

                            <InputTextarea
                              style={{ width: "100%" }}
                              id="template"
                              name="template"
                              value={input.template}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  template: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  template: "",
                                });
                              }}
                              rows={5}
                              cols={30}
                            />
                            {errors.template && (
                              <p style={{ color: "red", margin: 0 }}>
                                {errors.template}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="mt-3">
                          <label htmlFor="brandId" className="form-label">
                            Immediate Notify
                          </label>
                          <Dropdown
                            value={input.immediateNotify}
                            options={immediateNotifyList}
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              setInput({
                                ...input,
                                immediateNotify: e.target.value,
                              });
                            }}
                            optionLabel="label"
                            placeholder="Select a Immediate Notify"
                          />
                          {errors.immediateNotify && (
                            <p style={{ color: "red", margin: 0 }}>
                              {errors.immediateNotify}
                            </p>
                          )}
                        </div>

                        <div className="mt-3">
                          <label htmlFor="brandId" className="form-label">
                            Auto Notify Enabled
                          </label>
                          <Dropdown
                            value={input.autoNotifyEnabled}
                            options={immediateNotifyList}
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              setInput({
                                ...input,
                                autoNotifyEnabled: e.target.value,
                              });
                            }}
                            optionLabel="label"
                            placeholder="Select a Immediate Notify"
                          />
                          {errors.autoNotifyEnabled && (
                            <p style={{ color: "red", margin: 0 }}>
                              {errors.autoNotifyEnabled}
                            </p>
                          )}
                        </div>

                        <div className="mt-3">
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label htmlFor="status" className="form-label">
                                Status
                              </label>
                              <Dropdown
                                value={input.status}
                                options={StatusList}
                                style={{ width: "100%" }}
                                optionLabel="label"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    status: e.target.value,
                                  })
                                }
                                placeholder="Select a Status"
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="col-md-12 text-center">
                            <button
                              onClick={() => handleSubmit()}
                              className="move-btn move-btn-su"
                              disabled={submitButtonPressed}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditNotificationTemplate;
