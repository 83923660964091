import axios from "axios";
import { BRAND_BASE_URL } from "./BaseURL";
import getHeaders from "../constants/headers";

export const getBrandNotification = async (data) => {
  return axios({
    method: "post",

    url:
      BRAND_BASE_URL +
      "/store/api/secure/v1/notification-config/brand-notification", // for android use
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const postMapBrandNotification = async (data) => {
  return axios({
    method: "post",
    url:
      BRAND_BASE_URL +
      "/store/api/secure/v1/notification-config/map-brand-notification", // for android use
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const putMapBrandNotification = async (data) => {
  return axios({
    method: "put",
    url:
      BRAND_BASE_URL +
      "/store/api/secure/v1/notification-config/map-brand-notification", // for android use
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};