import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "../../index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../../utils/alertMessage";
import {
  getViewNotificationTemplate,
} from "../../../services/NotificationTemplate";
import { Dropdown } from "primereact/dropdown";
import {  putMapBrandNotification } from "../../../services/BrandNotiification";

const EditMapBrandNotificationTemplate = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [notificationTemplateList, setNotificationTemplateList] = useState([]);

  const fetchAllTemplate = () => {
    getViewNotificationTemplate()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const data = Res.map((item) => ({
            label: item.templateType,
            value: item.id,
          }));
          setNotificationTemplateList(data);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchAllTemplate();
  }, []);

  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const [input, setInput] = useState({
    whatsappNotify: state?.whatsappNotify || "",
    status: state?.status || ""
  });
  const [errors, setErrors] = useState({
    whatsappNotify: "",
  });

  useEffect(() => {
      if (state !== null) {
        setInput({
          ...input,
          whatsappNotify: {
            label: state.whatsappNotify === true ? "ACTIVE" : "INACTIVE",
            value: state.whatsappNotify,
          },
          status: {
            label: state.status === true ? "ACTIVE" : "INACTIVE",
            value: state.status,
          },
        });
      }
    }, [state]);

     const StatusList = [
       {
         label: "ACTIVE",
         value: true,
       },
       {
         label: "INACTIVE",
         value: false,
       },
     ];

     const whatsappNotifyList = [
       {
         label: "ACTIVE",
         value: true,
       },
       {
         label: "INACTIVE",
         value: false,
       },
     ];

  const handleSubmit = () => {
    if (input.templateType === "") {
      setErrors({
        ...errors,
        templateType: "Please Enter Template Type",
      });
    } else {
      setSubmitButtonPressed(true);
      const data = {
        id: state.id,
        whatsappNotify: input.whatsappNotify.value,
        configWithStore: true,
        status: input.status.value,
      };


      if (!data) {
        console.error("No file selected");
        return;
      }

      putMapBrandNotification(data)
        .then((response) => {
          // handle the response
          if (response.data.status === true) {
            successMessage(response.data.message);
            setSubmitButtonPressed(false);
            navigate("/brand-notification", {
              state: state.brandId,
            });
          } else {
            errorMessage(response.data.message);
            setSubmitButtonPressed(false);
          }
        })
        .catch((error) => {
          // handle errors
          errorMessage("Failed");
        });
    }
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() =>
              navigate("/brand-notification", {
                state: state.brandId,
              })
            }
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div>
              <div style={{ textAlign: "center" }}>
                <h3>Edit Notification Template </h3>
              </div>
              <main id="main" className="main mb-3">
                <section className="section">
                  <div className="col-lg-12">
                    <div>
                     {state?.templateType.autoNotifyEnabled === true && <div className="mt-3">
                        <label htmlFor="brandId" className="form-label">
                          WhatsApp Notify
                        </label>
                        <Dropdown
                          value={input.whatsappNotify}
                          options={whatsappNotifyList}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              whatsappNotify: e.target.value,
                            });
                          }}
                          optionLabel="label"
                          placeholder="Select a Whatsapp Notify"
                        />
                        {errors.whatsappNotify && (
                          <p style={{ color: "red", margin: 0 }}>
                            {errors.whatsappNotify}
                          </p>
                        )}
                      </div>}
                      <div className="mt-3">
                        <label htmlFor="brandId" className="form-label">
                          Status
                        </label>
                        <Dropdown
                          value={input.status}
                          options={StatusList}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              status: e.target.value,
                            });
                          }}
                          optionLabel="label"
                          placeholder="Select a status"
                        />
                      </div>

                      <div>
                        <div className="col-md-12 mt-4 text-center">
                          <button
                            onClick={() => handleSubmit()}
                            className="move-btn move-btn-su"
                            disabled={submitButtonPressed}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditMapBrandNotificationTemplate;
