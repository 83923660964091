

// curl --location 'https://dhobicart.in/store/api/secure/v1/notification-config/default-list' \
// --header 'Authorization: Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRob3JpdGllcyI6WyJST0xFX1NVUEVSX0FETUlOIl0sImRldmljZVRva2VuIjoiZDQxZDhjZDk4ZjAwYjIwNGU5ODAwOTk4ZWNmODQyN2UiLCJsb2dnZWRJblVzZXJJZCI6Ik9JVDM3NTcyIiwibG9nZ2VkSW5Vc2VyQnJhbmRJZCI6IjEiLCJzdWIiOiIxIiwiaWF0IjoxNzM1Nzk5NDM1LCJleHAiOjE3MzY0MDQyMzV9.OlypwIIny6ATuXl6mYHZKL5XaeC272pw1HVyIp6G-ALCeixhebiaK0HxnL39mKYB8V6kY3DvnRNX8xSuUvQckQ' \
// --header 'Cookie: JSESSIONID=EB155D939C74695C32CA684D0F968F29; JSESSIONID=68C8210FC27110563F06A19B91BFD1CD'



import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "../index.css";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { getViewNotificationTemplate } from "../../services/NotificationTemplate";

const ViewNotificationTemplate = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedViewAll, setSelectedViewAll] = useState("");

  const [leftCountValue, setLeftCountValue] = useState("");
  const [leftCountValueError, setLeftCountValueError] = useState("");

  const fetchAllTemplate = () => {
    setLoading(true);
    getViewNotificationTemplate()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setBrandList(Res);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchAllTemplate();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value); // Update searchQuery state with the input value
  };
  // Function to filter orderData based on search query

  const filteredData = brandList.filter((item) => {
    const query = searchQuery.toLowerCase();
    return item.templateType.toLowerCase().includes(query);
  });
  const [displayViewAll, setDisplayViewAll] = useState(false);
  const onClickViewAll = (item) => {
    setSelectedViewAll(item);
    setDisplayViewAll(true);
  };
  const onHideViewAll = () => {
    setDisplayViewAll(false);
  };
  const renderDialogViewAll = () => (
    <Dialog
      header="Template"
      visible={displayViewAll}
      onHide={() => onHideViewAll()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <div className="row mb-3">
          <div className="col-md-6">
            <h5 style={{ wordWrap: "break-word", color: "#000" }}>
              Template Name
            </h5>
            <p style={{ wordWrap: "break-word", color: "#000" }}>
              {selectedViewAll.templateType}
            </p>
          </div>
          <div className="col-md-6">
            <h5 style={{ wordWrap: "break-word", color: "#000" }}>
              WhatsApp Approved Name
            </h5>
            <p style={{ wordWrap: "break-word", color: "#000" }}>
              {selectedViewAll.whatsappTemplateApprovedName}
            </p>
          </div>
          <div className="col-md-12">
            <h6 style={{ wordWrap: "break-word", color: "blue" }}>
              {selectedViewAll.template}
            </h6>
          </div>
        </div>
      </div>
    </Dialog>
  );

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate("/")}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row className="mt-3 ml-3">
          <Col>
            <InputText
              type="text"
              value={searchQuery}
              onChange={handleSearchInputChange}
              placeholder="Search Template Name"
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link
              to="/add-notification-template"
              className="move-btn-a move-btn-mu center-text"
              variant="primary"
              style={{
                backgroundColor: "#2b70d7", // Change background color if selected
                color: "white", // Change text color if selected
                textAlign: "center",
                width: 120,
              }}
            >
              Add
            </Link>
          </Col>
        </Row>
        <Row>
          <Col style={{ margin: 10 }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ color: "blue", fontSize: "20px" }}
                  ></i>{" "}
                  Notification Template List is Loading
                </h3>
              </div>
            ) : filteredData.length > 0 ? (
              <div>
                <div style={{ textAlign: "center" }}>
                  <h3>Notification Template List </h3>
                </div>
                <main id="main" className="main mb-3">
                  <section className="section">
                    <div className="col-lg-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>S. No.</th>
                            <th>Template Name</th>
                            <th>Immediate Notify</th>
                            <th>Auto Notify Enabled</th>
                            <th>WhatsApp Template Approved Name</th>
                            <th>Edit</th>
                            <th>Template</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.templateType}</td>
                              <td>
                                {item.immediateNotify === true ? (
                                  <i
                                    className="pi pi-check"
                                    style={{
                                      color: "green",
                                      fontSize: "20px",
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="pi pi-times"
                                    style={{
                                      color: "red",
                                      fontSize: "20px",
                                    }}
                                  ></i>
                                )}
                              </td>
                              <td>
                                {item.autoNotifyEnabled === true ? (
                                  <i
                                    className="pi pi-check"
                                    style={{
                                      color: "green",
                                      fontSize: "20px",
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="pi pi-times"
                                    style={{
                                      color: "red",
                                      fontSize: "20px",
                                    }}
                                  ></i>
                                )}
                              </td>
                              <td>{item.whatsappTemplateApprovedName}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    navigate("/edit-notification-template", {
                                      state: item,
                                    })
                                  }
                                >
                                  <i
                                    className="pi pi-pencil"
                                    style={{
                                      color: "blue",
                                      fontSize: "20px",
                                    }}
                                  ></i>
                                </button>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() => onClickViewAll(item)}
                                >
                                  <i
                                    className="pi pi-eye"
                                    style={{ color: "blue", fontSize: "20px" }}
                                  ></i>
                                </button>
                              </td>
                              <td>
                                {item.status === true ? (
                                  <i
                                    className="pi pi-check"
                                    style={{
                                      color: "green",
                                      fontSize: "20px",
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="pi pi-times"
                                    style={{
                                      color: "red",
                                      fontSize: "20px",
                                    }}
                                  ></i>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </main>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>No Data Found</h3>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <div>{renderDialogViewAll()}</div>
    </div>
  );
};

export default ViewNotificationTemplate;
