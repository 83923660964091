import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "../../index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../../utils/alertMessage";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import {
  getViewNotificationTemplate,
  postNotificationTemplate,
} from "../../../services/NotificationTemplate";
import { Dropdown } from "primereact/dropdown";
import { postMapBrandNotification } from "../../../services/BrandNotiification";

const AddMapBrandNotificationTemplate = () => {
  const navigate = useNavigate();
  const { state } = useLocation();


  const [notificationTemplateList, setNotificationTemplateList] = useState([]);
  const [autoNotifyEnabledValue , setAutoNotifyEnabledValue] = useState(false);

  const statusList = [
    {
      label: "ACTIVE",
      value: true,
    },
    {
      label: "INACTIVE",
      value: false,
    },
  ];

  const fetchAllTemplate = () => {
    getViewNotificationTemplate()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const filteredData = Res.filter((item) => item.status === true);
          const data = filteredData.map((item) => ({
            label: item.templateType,
            value: item.id,
            autoNotifyEnabled: item.autoNotifyEnabled
          }));
          setNotificationTemplateList(data);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchAllTemplate();
  }, []);

  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const [input, setInput] = useState({
    templateType: "",
    whatsappNotify:"",
  });
  const [errors, setErrors] = useState({
    templateType: "",
    whatsappNotify:"",
  });

  const handleSubmit = () => {
    if (input.templateType === "") {
      setErrors({
        ...errors,
        templateType: "Please Enter Template Type",
      });
    } else {
      setSubmitButtonPressed(true);
      const data = {
        brandId: state,
        templateType: {
          id: input.templateType.value,
        },
        configWithStore: true,
        whatsappNotify: autoNotifyEnabledValue === true ? input.whatsappNotify.value : false,
      };


      if (!data) {
        console.error("No file selected");
        return;
      }

      postMapBrandNotification(data)
        .then((response) => {
          // handle the response
          if (response.data.status === true) {
            successMessage(response.data.message);
            setSubmitButtonPressed(false);
             navigate("/brand-notification", {
               state: state,
             });
          } else {
            errorMessage(response.data.message);
            setSubmitButtonPressed(false);
          }
        })
        .catch((error) => {
          // handle errors
          errorMessage("Failed");
        });
    }
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() =>
              navigate("/brand-notification", {
                state: state,
              })
            }
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div>
              <div style={{ textAlign: "center" }}>
                <h3>Add Notification Template </h3>
              </div>
              <main id="main" className="main mb-3">
                <section className="section">
                  <div className="col-lg-12">
                    <div>
                      <div className="mt-3">
                        <label htmlFor="brandId" className="form-label">
                          Template Type
                        </label>
                        <Dropdown
                          value={input.templateType}
                          options={notificationTemplateList}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              templateType: e.target.value,
                            });
                            setAutoNotifyEnabledValue(e.target.value.autoNotifyEnabled);
                          }}
                          optionLabel="label"
                          placeholder="Select a Template Type"
                        />
                        {errors.templateType && (
                          <p style={{ color: "red", margin: 0 }}>
                            {errors.templateType}
                          </p>
                        )}
                      </div>
                      {autoNotifyEnabledValue === true && <div className="mt-3">
                        <label htmlFor="brandId" className="form-label">
                          WhatsApp Notify
                        </label>
                        <Dropdown
                          value={input.whatsappNotify}
                          options={statusList}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              whatsappNotify: e.target.value,
                            });
                          }}
                          optionLabel="label"
                          placeholder="Select a Whatsapp Notify"
                        />
                        {errors.whatsappNotify && (
                          <p style={{ color: "red", margin: 0 }}>
                            {errors.whatsappNotify}
                          </p>
                        )}
                      </div>}

                      <div>
                        <div className="col-md-12 mt-4 text-center">
                          <button
                            onClick={() => handleSubmit()}
                            className="move-btn move-btn-su"
                            disabled={submitButtonPressed}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddMapBrandNotificationTemplate;
